let PackagesItem = [
	{
		track_mode: `junior`,
		track_title: `Junior Day`,
		track_desc: `[ Entry-level ]`,
		track_talks: `10+`,
		track_option_1: true,
		track_option_2: false,
		track_option_3: false,
		track_option_4: false,
		track_option_5: false,
		track_option_6: false,
		track_option_7: false,
		track_price_full: `Free`,
		track_price: `$0`,
		track_button: `Register`,
		track_button_desc: `[ 100 spots left ]`,
		track_link: `https://geekle.us/ticketfree/1721377925203x323243337885941760`,
	},
	{
		track_mode: `senior`,
		track_title: `Senior Day`,
		track_desc: `[ Deep-level ]`,
		track_talks: `20+`,
		track_option_1: true,
		track_option_2: true,
		track_option_3: true,
		track_option_4: true,
		track_option_5: true,
		track_option_6: false,
		track_option_7: false,
		track_price_full: `$249`,
		track_price: `$69`,
		track_button: `Register`,
		track_button_desc: ``,
		track_link: `https://geekle.us/ticket/1721377730369x309848772851793900`,
	},
	{
		track_mode: `year`,
		track_title: `Year of QA`,
		track_desc: `[ Most Popular ]`,
		track_talks: `ALL`,
		track_option_1: true,
		track_option_2: true,
		track_option_3: true,
		track_option_4: true,
		track_option_5: true,
		track_option_6: true,
		track_option_7: true,
		track_price_full: `$499`,
		track_price: `$159`,
		track_button: `Register`,
		track_button_desc: ``,
		track_link: `https://geekle.us/ticket/1721377629301x744316922489733100`,
	},
];

export default PackagesItem;
