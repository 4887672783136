import './TagCloud.css';
import React from 'react';

export default class Tagscloud extends React.Component {
    render() {
        return (
            <div className='tagscloud'>
                <div className='container'>
                    <h2 className='tagscloud_title'>What’s QA and Testing in 2024?</h2>
                    <div className='tagscloud_blocks'>
                        <div className='tagscloud_block'>
                            <span className='tagscloud_item--1'>QA Engineer</span>
                            <span className='tagscloud_item--2'>Manual testing</span>
                            <span className='tagscloud_item--3'>Visual Testing</span>
                            <span className='tagscloud_item--4'>Test Cases</span>
                        </div>
                        <div className='tagscloud_block'>
                            <span className='tagscloud_item--5'>Playwright</span>
                            <span className='tagscloud_item--6'>Test Data</span>
                            <span className='tagscloud_item--7'>Testing in Production</span>
                            <span className='tagscloud_item--8'>Test Techniques</span>
                            <span className='tagscloud_item--9'>Cucumber</span>
                            <span className='tagscloud_item--10'>The Future of<br />Software Testing</span>
                        </div>
                        <div className='tagscloud_block tagscloud_block--left'>
                            <span className='tagscloud_item--11'>Tester</span>
                            <span className='tagscloud_item--12'>CI/CD testing</span>
                            <span className='tagscloud_item--13'>DevOps and Agile at scale</span>
                        </div>
                        <div className='tagscloud_block'>
                            <span className='tagscloud_item--14'>Software Testing 2023</span>
                            <span className='tagscloud_item--15'>Codeless Automation</span>
                            <span className='tagscloud_item--16'>Automation</span>
                            <span className='tagscloud_item--17'>SDLC</span>
                            <span className='tagscloud_item--18'>Selenium</span>
                        </div>
                        <div className='tagscloud_block tagscloud_block--left'>
                            <span className='tagscloud_item--19'>Functional testing</span>
                            <span className='tagscloud_item--20'>Cypress</span>
                            <span className='tagscloud_item--21'>Security Testing</span>
                            <span className='tagscloud_item--22'>End to End</span>
                        </div>
                        <div className='tagscloud_block'>
                            <span className='tagscloud_item--23'>PageObjects</span>
                            <span className='tagscloud_item--24'>Testability</span>
                            <span className='tagscloud_item--25'>Test Management</span>
                            <span className='tagscloud_item--26'>Quality Assurance</span>
                            <span className='tagscloud_item--27'>Methods and Techniques</span>
                        </div>
                        <div className='tagscloud_block'>
                            <span className='tagscloud_item--28'>Exploratory Testing</span>
                            <span className='tagscloud_item--29'>UI/UX Testing</span>
                            <span className='tagscloud_item--30'>Mobile testing</span>
                            <span className='tagscloud_item--31'>API testing</span>
                            <span className='tagscloud_item--32'>BDD</span>
                            <span className='tagscloud_item--33'>AI/ML for Testing</span>
                        </div>
                        <div className='tagscloud_block'>
                            <span className='tagscloud_item--34'>Test coverage</span>
                            <span className='tagscloud_item--35'>WebDriver</span>
                            <span className='tagscloud_item--36'>TDD</span>
                            <span className='tagscloud_item--37'>Mutation testing</span>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}