import './CountDownBanner.css';
import React, { useEffect, useState } from 'react';
import CountDown from "../CountDown/CountDown.jsx";
import CountDownText from '../CountDownText/CountDownText.jsx';
import { Link } from "react-scroll";
import close from '../../img/close_popup.svg';

const CountDownBanner = () => {
    const [isBannerVisible, setIsBannerVisible] = useState(true);
    const [scrollY, setScrollY] = useState(0);
    const [showCountDown, setShowCountDown] = useState(false);

    const handleScroll = () => {
        setScrollY(window.scrollY);
    };

    useEffect(() => {
        // Відстежуємо скролл для зміни видимості банера
        window.addEventListener("scroll", handleScroll);
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    useEffect(() => {
        // Покажемо CountDown з затримкою після прокрутки
        if (scrollY > 500) {
            const timeout = setTimeout(() => {
                setShowCountDown(true);
            }, 3500); // Затримка 3.5 секунди

            return () => clearTimeout(timeout);
        }
    }, [scrollY]); // Залежність від scrollY

    const toggleBanner = () => {
        setIsBannerVisible(!isBannerVisible);
    };

    return (
        <>
            {scrollY > 500 && (
                <div>
                    {isBannerVisible && (
                        <div className='countdown-wrap red_grad'>
                            <div id="countdown" className="countdown">
                                <div className='countdown-content-block'>
                                    <CountDownText />
                                    <div className='countdown-main'>
                                        <CountDown />
                                    </div>
                                    <Link
                                        activeClass="active"
                                        to='tickets'
                                        spy={true}
                                        smooth={true}
                                        offset={0}
                                        className='countdown_btn'
                                    >
                                        GET TICKET
                                    </Link>
                                </div>
                                <img onClick={toggleBanner} className='close_countdown' src={close} alt='close' />
                            </div>
                        </div>
                    )}
                </div>
            )}
        </>
    );
};

export default CountDownBanner;
