import React from 'react';
import './Speakers.css';
import VideoPresent from './VideoPresent/VideoPresent';

export default class SpeakersList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            junior_speakers: [],
            senior_speakers: [],
        }
    }

    componentDidMount() {
        fetch(
            "https://geekle.us/api/1.1/wf/api_1_get_eventspeakers?event_track=1711724426766x381069159944421400")
            .then((res) => res.json())
            .then((json) => {
                this.setState({
                    junior_speakers: json,
                });
            })

        fetch(
            "https://geekle.us/api/1.1/wf/api_1_get_eventspeakers?event_track=1711724432109x108106260855390200")
            .then((res) => res.json())
            .then((json) => {
                this.setState({
                    senior_speakers: json,
                });
            })
    }

    render() {
        return (
            <div className="speakers" id='speakers'>
                <VideoPresent speakersJunior={this.state.junior_speakers} speakersSenior={this.state.senior_speakers} />
            </div >
        );
    }
};