import React, { useState, useEffect } from 'react';
import moment from 'moment'; // Імпорт moment.js
import './CountDown.css';

const CountDown = () => {
	const initialDeadLine = moment.utc('2024-10-05T10:00:00+03:00'); // Задання дедлайну

	const calculateTimeLeft = (targetDate) => {
		const now = moment(); // Отримуємо поточний момент
		let remainingTime = targetDate.clone(); // Клонуємо дедлайн для обчислення

		// Якщо поточна дата більша за дедлайн, додаємо 7 днів
		if (now.isAfter(remainingTime)) {
			remainingTime.add(7, 'days'); // Додаємо 7 днів
		}

		const diffDuration = moment.duration(remainingTime.diff(now)); // Різниця часу
		let timeLeft = {};

		if (diffDuration.asSeconds() > 0) {
			timeLeft = {
				days: Math.floor(diffDuration.asDays()),
				hours: diffDuration.hours(),
				minutes: diffDuration.minutes(),
				seconds: diffDuration.seconds(),
			};
		} else {
			timeLeft = { days: 0, hours: 0, minutes: 0, seconds: 0 };
		}

		return { timeLeft, remainingTime }; // Повертаємо залишковий час і новий дедлайн
	};

	const [timeLeft, setTimeLeft] = useState(calculateTimeLeft(initialDeadLine).timeLeft);
	const [remainingTime, setRemainingTime] = useState(initialDeadLine);

	useEffect(() => {
		const timer = setInterval(() => {
			const { timeLeft, remainingTime: newRemainingTime } = calculateTimeLeft(remainingTime);
			setTimeLeft(timeLeft);
			setRemainingTime(newRemainingTime); // Оновлюємо новий дедлайн

			// Якщо дедлайн сплив, оновлюємо дедлайн
			if (moment().isAfter(newRemainingTime)) {
				setRemainingTime(newRemainingTime.clone().add(7, 'days'));
			}

		}, 1000);

		return () => clearInterval(timer);
	}, [remainingTime]); // Залежність від remainingTime

	const renderDigits = (number, label) => {
		const digits = String(number).padStart(2, '0').split('');
		return (
			<div className='time-block'>
				<div className='digits'>
					<span className='digit'>{digits[0]}</span>
					<span className='digit'>{digits[1]}</span>
				</div>
				<span className='label'>{label}</span>
			</div>
		);
	};

	return (
        <div className='timer'>
            {renderDigits(timeLeft.days, 'days')}
            <span className='colon'>:</span>
            {renderDigits(timeLeft.hours, 'hours')}
            <span className='colon'>:</span>
            {renderDigits(timeLeft.minutes, 'minutes')}
            <span className='colon'>:</span>
            {renderDigits(timeLeft.seconds, 'seconds')}
        </div>
	);
};

export default CountDown;
