import React from 'react';
import './PC.css';
import Rusinchuk from '../../img/pc2/Rusinchuk.png'
import Laichenkov from '../../img/pc2/Laichenkov.png'

export default class PC extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      pc: [
        {
          photo: Rusinchuk,
          name: 'Sergii Rusinchuk',
          position: 'Head of QA at CUCollaborate | Mentor | Speaker',
          ln: 'https://www.linkedin.com/in/sergii-rusinchuk-554525192/',
        },
        {
          photo: Laichenkov,
          name: 'Yevhen Laichenkov',
          position: 'Software Engineer in Test at Charter Communications',
          ln: 'https://www.linkedin.com/in/elaichenkov',
          github: 'https://github.com/elaichenkov',
          tw: 'https://twitter.com/elaichenkov',
        },
      ],
      // pcHide: [
      // ],
      hide: true,
    }
    this.togglePC = this.togglePC.bind(this);
  }

  togglePC() {
    this.setState({ hide: !this.state.hide });
  }

  renderPC(item, i) {
    return (
      <div className="pc_item" id='pcc' key={`team-${i}`}>
        <div className="pc_img_block">
          <img className="team_img" src={item.photo} alt={item.name} />
        </div>
        <div className="pc_info">
          <p className="pc_name">{item.name}</p>
          <p className="pc_position">{item.position}</p>
          <div className="pc_links">
            {item.fb && <a className="pc_link social_fb" target='_blank' aria-label="Facebook Icon" rel="noreferrer" href={item.fb}></a>}
            {item.ln && <a className="pc_link social_ln" target='_blank' aria-label="LinkedIn Icon" rel="noreferrer" href={item.ln}></a>}
            {item.tw && <a className="pc_link social_tw" target='_blank' aria-label="Twitter Icon" rel="noreferrer" href={item.tw}></a>}
            {item.github && <a className="pc_link social_github" target='_blank' aria-label="GitHub Icon" rel="noreferrer" href={item.github}></a>}
            {item.website && <a className="pc_link social_website" target='_blank' rel="noreferrer" href={item.website}></a>}
            {item.youtube && <a className="pc_link social_yb" target='_blank' aria-label="Youtube Icon" rel="noreferrer" href={item.youtube}></a>}
          </div>
          {/* <img className="pc_logo" src={item.logo} alt="" /> */}
        </div>
      </div>
    )
  }

  render() {
    return (
      <div className="pc container" id='pc'>
        <h2>Program committee</h2>
        <div className='pc_wrap'>
          <div className="pc_block">
            {this.state.pc.map((item, i) => this.renderPC(item, i))}
            {/* {!this.state.hide && this.state.pcHide.map((item, i) => this.renderPC(item, i))} */}
          </div>
          {/* <button className='pc_btn' onClick={this.togglePC}>{this.state.hide ? 'Show more' : 'Hide'}</button> */}
        </div>
      </div>
    )
  }
}