import React from "react";
import "./Footer.css";
import logo from "../../img/footer/logo.svg";
import fb from "../../img/footer/fb-white.svg";
import ln from "../../img/footer/ln-white.svg";
import twitter from "../../img/footer/tw-white.svg";
import inst from "../../img/footer/inst-white.svg";
import yb from "../../img/footer/yb-white.svg";

export default class Footer extends React.Component {
  render() {
    return (
      <div className='footer_wrap'>
        <div className='container footer'>
          <div className='footer_info'>
            <div className='footer_docs footer_item'>
              <a rel="noreferrer" className='footer_code footer_docs_item p1' href="https://geekle.us/code-of-conduct" target='_blank'>Code of Conduct</a>
              <a rel="noreferrer" className='footer_legal footer_docs_item p1' href="https://geekle.us/legal-docs" target='_blank'>Legal Docs</a>
            </div>
            <div className='footer_address footer_address--mob footer_item p1'>Geekle Corp.<br /> 910 Foulk Road, Suite 201<br /> Wilmington, DE<br /> 19803, USA</div>
            <div className='footer_contacts footer_item'>
              <div className='footer_contacts_wrap'>
                <svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M18.0004 12.4983L12.7304 11.8883L10.2104 14.4083C7.37158 12.9646 5.06417 10.6571 3.62039 7.81833L6.15039 5.28833L5.54039 0.0383301H0.0303908C-0.549609 10.2183 7.82039 18.5883 18.0004 18.0083V12.4983Z" fill="#0260e8" />
                </svg>
                <a rel="noreferrer" className='footer_phone footer_contact p2' href="tel:+447418357819" target='_blank'>+447 418 357 819</a>
              </div>
              <div className='footer_contacts_wrap'>
                <svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M18.0004 12.4983L12.7304 11.8883L10.2104 14.4083C7.37158 12.9646 5.06417 10.6571 3.62039 7.81833L6.15039 5.28833L5.54039 0.0383301H0.0303908C-0.549609 10.2183 7.82039 18.5883 18.0004 18.0083V12.4983Z" fill="#0260e8" />
                </svg>
                <a rel="noreferrer" className='footer_phone footer_contact p2' href="tel:+14156385298" target='_blank'>+141 563 852 98</a>
              </div>
              <div className='footer_contacts_wrap'>
                <svg width="20" height="17" viewBox="0 0 20 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M0 16.5283V0.52832H20V16.5283H0ZM10 9.52832L18 4.52832V2.52832L10 7.52832L2 2.52832V4.52832L10 9.52832Z" fill="#0260e8" />
                </svg>
                <a rel="noreferrer" className='footer_mail footer_contact p2' href="mailto:info@geekle.us" target='_blank'>info@geekle.us</a>
              </div>
            </div>
          </div>
          <div className='footer_links footer_item'>
            <a rel="noreferrer" className='footer_link_geekle' href="https://geekle.us/" target='_blank'>
              <img className='footer_geekle_logo' src={logo} alt="Geekle logo" />
            </a>
            <div className='footer_link_block'>
              <a rel="noreferrer" className='footer_link footer_link--ln' target='_blank' href='https://www.linkedin.com/company/34674119/admin/'>
                <img className='footer_link_img' src={ln} alt="Geekle linkedin" />
              </a>
              <a rel="noreferrer" className='footer_link footer_link--fb' target='_blank' href='https://www.facebook.com/geekleclubs/'>
                <img className='footer_link_img' src={fb} alt="Geekle facebook" />
              </a>
              <a rel="noreferrer" className='footer_link footer_link--twitter' target='_blank' href='https://twitter.com/GeekleOfficial'>
                <img className='footer_link_img' src={twitter} alt="Geekle twitter" />
              </a>
              <a rel="noreferrer" className='footer_link footer_link--inst' target='_blank' href='https://www.instagram.com/geekle.us/'>
                <img className='footer_link_img' src={inst} alt="Geekle instagram" />
              </a>
              <a rel="noreferrer" className='footer_link footer_link--yb' target='_blank' href='https://www.youtube.com/channel/UCT-RTQuSWLsJXytEy7Q7n4g'>
                <img className='footer_link_img' src={yb} alt="Geekle youtube" />
              </a>
            </div>
          </div>
          <div className="chat_block">
            <p className="chat_title">Any questions left?</p>
            <p className="p1 chat_undertitle">We are happy to talk</p>
            <a target='_blank' rel='noreferrer' className="chat_btn button" href="https://tawk.to/chat/62d53950b0d10b6f3e7ce13c/1g92ed1m4">
              <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M0.509766 20.3262V2.32617C0.509766 1.77617 0.706915 1.30517 1.10121 0.913172C1.49484 0.521838 1.96827 0.326172 2.5215 0.326172H18.6153C19.1686 0.326172 19.6423 0.521838 20.0366 0.913172C20.4302 1.30517 20.6271 1.77617 20.6271 2.32617V14.3262C20.6271 14.8762 20.4302 15.3472 20.0366 15.7392C19.6423 16.1305 19.1686 16.3262 18.6153 16.3262H4.53322L0.509766 20.3262Z" fill="white" />
              </svg>
              chat</a>
          </div>
        </div>
        <div className='footer_address footer_address--pc footer_item'>Geekle Corp. 910 Foulk Road, Suite 201 Wilmington, DE 19803, USA</div>
      </div>
    )
  }
}