import React, { useState, useEffect } from 'react';
import './Timer.css';
import CountDown from '../../CountDown/CountDown';

const Timer = () => {
	return (
		<div className='timer_cont'>
			<h4>🔥 Save $301 on a Senior Day ticket! Sale ends this Saturday! 🔥</h4>
			<CountDown />
		</div>
	);
};

export default Timer;
