import React, { useState } from "react";
import { Link } from "react-scroll";
import hamburger from "../../../img/burger.svg";
import close from "../../../img/close.svg";
import OpenMenu from "./OpenMenu/OpenMenu.jsx";
import logoGeekle from '../../../img/logo.svg'
import './Menu.css';

const Menu = (props) => {
  const [showText, setShowText] = useState(false);
  const onClick = () => setShowText(true);
  const onClickclose = () => setShowText(false);

  return (
    <>
      <ul className='menu'>
        <li className='menu_pc menu_pc--without_before'>
          <Link
            activeClass="active"
            to='speakers'
            spy={true}
            smooth={true}
            offset={-94}
          >
            Speakers
          </Link>
        </li>
        {/* <li className='menu_pc'>
          <Link
            activeClass="active"
            to='agenda'
            spy={true}
            smooth={true}
            offset={-94}
          >
            Agenda
          </Link>
        </li> */}
        <li className='menu_pc menu_pc--after'>
          <Link
            activeClass="active"
            to='tickets'
            spy={true}
            smooth={true}
            offset={0}
          >
            Tickets
          </Link>
        </li>
        {/* <li className='menu_pc'>
          <Link
            activeClass="active"
            to='partners'
            spy={true}
            smooth={true}
            offset={-94}
          >
            Partners
          </Link>
        </li> */}
        {/* <li className='menu_pc'>
          <Link
            activeClass="active"
            to='pc'
            spy={true}
            smooth={true}
            offset={-94}
          >
            Committee
          </Link>
        </li> */}
        <li className='menu_pc'>
          <Link
            activeClass="active"
            to='sponsorship'
            spy={true}
            smooth={true}
            offset={-94}
          >
            Become a sponsor
          </Link>
        </li>
        {/* <li className='menu_pc menu_pc--after'>
          <Link
            activeClass="active"
            to='team'
            spy={true}
            smooth={true}
            offset={-94}
          >
            Team
          </Link>
        </li> */}
        <div className="mobile-menu">
          <a className="logo-mob" href='https://geekle.us/' target='_blank' rel='noreferrer'>
            <img src={logoGeekle} alt="" />
          </a>
          <div className="mobile-menu_img-block">
            {showText === false && <img alt='' src={hamburger} onClick={onClick} className="hamburgerMenu mobile-menu-img" />}
            {showText === true && <img alt='' src={close} onClick={onClickclose} className="hamburgerMenu mobile-menu-img" />}
          </div>
          <div>
            {showText ? (
              <React.Fragment>
                <OpenMenu close={onClickclose} />
                <div className="mob_blur_bcg"></div>
              </React.Fragment>
            ) : null}
          </div>
        </div>
      </ul>
    </>
  );
}

export default Menu;