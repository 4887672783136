import React from 'react';
import './VideoPresent.css';
import Popup from './Popup/Popup.jsx'

export default class VideoPresent extends React.Component {
  renderVideoPresent(item, i) {
    return (
      <div className={`agenda_item ${!item.abstract ? 'panel' : '' }`}>
        <div className="agenda_data">
          <p className="agenda_time p1">{item.time}</p>
          <p className="agenda_data--day p1">{item.day}</p>
        </div>
        {item.photo && <div className='agenda_photo_block'>
          <img className="agenda_photo" src={item.photo} alt="" />
        </div>}
        <div className="agenda_bio">
          <p className="agenda_name p2">{item.name}</p>
          <p className="agenda_position p1">{item.position}</p>
          {item.logo && <img className="agenda_logo" src={item.logo} alt="" />}
          {item.logoText && <p className='p2 company_text'>{item.logoText}</p>}
          <div className='agenda_social'>
            {item.tw && <a className='agenda_link social_link social_tw' target='_blank' rel="noreferrer" href={item.tw} />}
            {item.github && <a className='agenda_link agenda_github social_link' target='_blank' rel="noreferrer" href={item.github} />}
            {item.ln && <a className='agenda_link social_link social_ln' target='_blank' rel="noreferrer" href={item.ln} />}
            {item.fb && <a className='agenda_link social_link social_fb' target='_blank' rel="noreferrer" href={item.fb} />}
            {item.website && <a className='agenda_link social_link social_website' target='_blank' rel="noreferrer" href={item.website} />}
            {item.inst && <a className='agenda_link social_link social_inst' target='_blank' rel="noreferrer" href={item.inst} />}
            {item.yb && <a className='agenda_link social_link social_yb' target='_blank' rel="noreferrer" href={item.yb} />}
          </div>
        </div>
        <div className="agenda_info">
          <p className="agenda_topic p2">{`${item.title ? `${'"'}` + item.title + `${'"'}` : 'Topic TBA'}`}</p>
          <div className={`agenda_btns ${!item.abstract ? 'one_btn' : '' }`}>
          {item.abstract && <Popup quotes={true} desc2={item.bio} title={item.title} title2='More about speaker' desc={item.abstract} key={i} />}
            {item.linkAdd && <a className="button agenda_btn--add_calendar" target='_blank' rel='norefferer' href={item.linkAdd}>add to calendar</a>}
          </div>
        </div>
      </div>
    )
  }

  render() {
    return (
      <div className="agenda_block">
        {this.props.speakers.map((item, i) => <React.Fragment key={`agenda_item-${i}`}>{this.renderVideoPresent(item, i)}</React.Fragment>)}
      </div>
    )
  }
}