import AskCompany from './AskCompany/AskCompany';
import Enterprise from './Enterprise/Enterprise';
import Packages from './Packages/Packages';
import './PackagesWrap.css';
import Timer from './Timer/Timer';

const PackagesWrap = () => {
	return (
		<section id='tickets'>
			<div className='container'>
				<h2> {'< Packages >'}</h2>
				<div className='tickets_container'>
					<Packages />
					<Timer />
					<AskCompany />
					<Enterprise />
				</div>
			</div>
		</section>
	);
};

export default PackagesWrap;
