import React from 'react';
import './BecomeSpeaker.css';
import img1 from '../../img/Become/1.png'
import img2 from '../../img/Become/2.png'
import img3 from '../../img/Become/3.png'
import { Carousel } from 'react-responsive-carousel';

const BecomeSpeaker = () => {
    const state = {
        Become_item: [
            {
                img: img1,
                title: 'Give back to Community',
                desc: 'Contribute to development of an international IT community',
            },
            {
                img: img2,
                title: 'Develop your Personal Brand',
                desc: 'Show the world how cool you are at the software engineering',
            },
            {
                img: img3,
                title: 'Network with tech leaders',
                desc: 'Have fun with the world’s technical influencers together',
            },
        ],
    };

    const renderBecome_item = (item, i) => {
        return (
            <div className='become_item' key={`become_item-${i}`}>
                <img className='become_img' src={item.img} alt="" />
                <div className='become_item--info'>
                    <h3 className='become_title'>{item.title}</h3>
                    <p className='become_desc'>{item.desc}</p>
                </div>
            </div>
        );
    };

    return (
        <section className="become_wrap black" id='become'>
            <div className="container become">
                <h2 className="become_title_h2 colorful">Join as a Speaker</h2>
                <h3 className="become_title_h3">Why join Geekle</h3>
                <div className="become_items">
                    {state.Become_item.map((item, i) => renderBecome_item(item, i))}
                </div>
                <Carousel className="become_slider team_slider" infiniteLoop={true} centerMode={true} centerSlidePercentage={95} showThumbs={false} showStatus={false} swipeable={false} emulateTouch={false}>
                    {state.Become_item.map((item, i) => renderBecome_item(item, i))}
                </Carousel>
                <a target='_blank' rel='noreferrer' className='button become_btn' href="https://forms.gle/g9h5S1P8LjgXQGsCA">apply</a>
            </div>
        </section>
    );
};

export default BecomeSpeaker;