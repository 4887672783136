import React from "react"; 
import './GoToPackages.css';
import { Link } from "react-scroll";

const GoToPackages = (props) => {
  return (
    <section className="GoToPackages">
        <Link
            activeClass="active"
            className="button"
            to='packages'
            spy={true}
            smooth={true}
            offset={-94}
          >
            GO TO PACKAGES
          </Link>
    </section>
  );
}

export default GoToPackages;