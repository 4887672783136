import React from 'react';
import './team.css';
import Ed from '../../img/Team/Ed.png';
import Sia from '../../img/Team/Sia.png';
import { Carousel } from 'react-responsive-carousel';

export default class Team extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      team: [
        {
          photo: Sia,
          name: 'Sia Snitsarenko',
          position: 'Event manager',
          ln: 'https://www.linkedin.com/in/siasnitsarenko',
        },
        {
          photo: Ed,
          name: 'Ed Nedin',
          position: 'Chief Executive Officer Geekle',
          ln: 'https://www.linkedin.com/in/ed-nedin-b05a95212/',
        },
      ]
    }
  }

  renderTeam(item, i) {
    return (
      <div className="team_item" key={`team-${i}`}>
        <div className="team_item--inner">
          <div className="team_img_block">
            <img className="team_img" src={item.photo} alt={item.name} />
          </div>
          <div className="team_info">
            <p className="team_name p1">{item.name}</p>
            <p className="team_position p2">{item.position}</p>
            <div className="team_links">
              {item.mail && <a className="team_link social_link social_mail" target='_blank' rel="noreferrer" href={item.mail}></a>}
              {item.ln && <a className="team_link social_link social_ln" target='_blank' rel="noreferrer" href={item.ln}></a>}
              {item.fb && <a className="team_link social_link social_fb" target='_blank' rel="noreferrer" href={item.fb}></a>}
            </div>
          </div>
        </div>
      </div>
    )
  }

  render() {
    return (
      <section className="ourTeam" id='team'>
        <div className='container team_wrap'>
          <h2 className='team_h2 colorful'>Our Team</h2>
          <div className="team_block">
            {this.state.team.map((item, i) => this.renderTeam(item, i))}
          </div>
          <Carousel className="team_slider" infiniteLoop={true} centerMode={true} centerSlidePercentage={95} showThumbs={false} showStatus={false} swipeable={false} emulateTouch={false}>
            {this.state.team.map((item, i) => this.renderTeam(item, i))}
          </Carousel>
        </div>
      </section>
    )
  }
}