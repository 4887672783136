import { useState, useEffect } from 'react';
import OptionsInfo from "./Options.js";
// import { Options } from './Options.js';
import PackagesInfofrom from "./PackagesInfo.js";
import "./Packages.scss";
import Active from './img/active.svg';
import Inactive from './img/inactive.svg';




const Packages = () => {
    const [isMobileView, setIsMobileView] = useState(window.innerWidth < 850);
   
    useEffect(() => {
        const handleResize = () => {
            setIsMobileView(window.innerWidth < 850);
        };
    
        window.addEventListener('resize', handleResize);
    
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);
   
    let OptionsElements = OptionsInfo.options_list.map((option, i) => {
        return <p key={`option-${i}`}>{option.label}</p>
    });
    
    let packagesElements = PackagesInfofrom.map((ticket, i) => {
        return (
            <div key={`ticket-${i}`} className={`tickets__item ${ticket.track_mode}`}>
                <h4 className='tickets__title'>
                    {ticket.track_title} 
                    <span className='tickets__desc'>{ticket.track_desc} </span>
                </h4>
                {isMobileView ? ( 
                    <p className='tickets__talks'>
                        <span>{OptionsInfo.option_talks}</span>
                        <span>{ticket.track_talks}</span>
                    </p>
                ) : (
                    <p>{ticket.track_talks}</p>
                )}
                {OptionsInfo.options_list.map((option, index) => (
                    <p className={ticket[option.key] ? 'green' : 'red'} key={`option-${index}`}>
                    {isMobileView ? ( 
                        ticket[option.key] ? 
                            <>
                                <img className='tickets__img' src={Active} alt="" /> 
                                <span>{option.label}</span>
                            </>
                            : 
                            <>
                                <img className='tickets__img' src={Inactive} alt="" />
                                <span>{option.label}</span>
                            </>
                    ) : (
                        ticket[option.key] ? 'YES' : 'NO'
                    )}
                </p>
                ))}
                {isMobileView ? ( 
                    <p className='tickets__price'>
                        <span>{OptionsInfo.option_price}</span>
                        <span>{ticket.track_price_full}</span>
                    </p>
                ) : (
                    <p className='tickets__price'>{ticket.track_price_full}</p>
                )}
                {isMobileView ? (
                    ticket.track_mode == 'junior' ? 
                        ''
                    : 
                        <p className='tickets__sale'>{OptionsInfo.option_sale}</p>
                ) : (
                    ''
                )}
                <div className='tickets__btn-wrap'>
                    <a
                        className='tickets__btn'
                        href={ticket.track_link}
                    >
                        {ticket.track_button} {ticket.track_price} 
                        {ticket.track_button_desc ? <span className='tickets__spots'>{ticket.track_button_desc}</span> : ''}
                    </a>
                </div>
            </div>
        )
    });
	return (
        <>
            <div className='tickets__wrap'>
                <div className='tickets__options'>
                    <p>{OptionsInfo.option_title}</p>
                    <p>{OptionsInfo.option_talks}</p>
                    {OptionsElements}
                    <p>{OptionsInfo.option_price}</p>
                    <p>{OptionsInfo.option_sale}</p>
                </div>
                {packagesElements}
            </div>
            {isMobileView ? (
                packagesElements
            ) : (
                ''
            )}
        </>
	);
};

export default Packages;
