import React from 'react';
import './Popup.css';
import closeImg from '../../../../img/close_popup.svg'
import { Link } from "react-scroll";
import Popup from 'reactjs-popup';
import ReactPlayer from "react-player";

export default class Times extends React.Component {
    render() {
        return (
            <Popup
                trigger={<button className='agenda_btn button agenda_btn-white'> Show more </button>}
                modal
                nested
                onOpen={() => { document.querySelector('html').style.setProperty('overflow', 'hidden'); document.querySelector('html').style.setProperty('position', 'relative'); document.querySelector('html').style.setProperty('height', '100%') }}
                onClose={() => { document.querySelector('html').style.setProperty('overflow', 'auto') }}
            >
                {close => (
                    <>
                        <img tabIndex={0} src={closeImg} className="close" alt='' onKeyDown={close} onClick={close} />
                        <div className="modal">
                            <h4 className='popup_title'>{this.props.quotes && '"'}{this.props.title}{this.props.quotes && '"'}</h4>
                            {this.props.video && <ReactPlayer
                                url={this.props.video}
                                width="100%"
                                height="100%"
                                controls
                                playIcon={<button className='videoPresent_item-play'></button>}
                                className='popup_video'
                            />}
                            <p className='popup_abstract' dangerouslySetInnerHTML={{__html: this.props.desc.replace(/\n/g, '<br>')}}></p>
                            {this.props.desc2 && <><h4 className='popup_title'>{this.props.title2}</h4>
                                <p className='popup_abstract'>{this.props.desc2}</p></>}
                            <Link
                                activeClass="active"
                                to='tickets'
                                spy={true}
                                smooth={true}
                                offset={-10}
                                className='button popup2'
                                onClick={close}
                            >
                                <span className='main_btn_span'>Get Ticket</span>
                            </Link>
                        </div>
                    </>
                )}
            </Popup>
        )
    }
}