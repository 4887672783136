const Options = {
	option_title: `Ticket Options:`,
	option_talks: `Speakers' talks`,
	options_list: [
		{ key: 'track_option_1', label: 'Junior Day live stream access' },
		{ key: 'track_option_2', label: 'Senior Day live stream access' },
		{ key: 'track_option_3', label: 'Micro Workshops access' },
		{ key: 'track_option_4', label: 'Certificate of Attendance' },
		{
			key: 'track_option_5',
			label: 'Recordings of ALL Sessions after the event',
		},
		{
			key: 'track_option_6',
			label: 'Senior Day ticket on the QA Global Summit’25',
		},
		{
			key: 'track_option_7',
			label: 'Access to all QA videos on the Geekle Platform',
		},
	],
	option_price: `Final Price`,
	option_sale: `SALE PRICE: ends this Saturday`,
};

export default Options;