import React from 'react';
import { Link } from "react-scroll";
import './Mission.css';
import MissionImg from '../../img/Mission/Mission_img.jpg'

export default class Mission extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isVisible: false,
        }
        this.changeVisState = this.changeVisState.bind(this);
    }

    changeVisState() {
        this.setState({ isVisible: !this.state.isVisible })
    }

    render() {
        return (
            <section className="mission">
                <div className='container'>
                    <img className='mission_img' src={MissionImg} alt="" />
                    <div className="mission_text_block mission_item">
                        <h2 className="mission_title colorful">Our mission</h2>
                        <div onClick={() => this.changeVisState()} className={`mission_text_hide ${this.state.isVisible === true ? 'active' : ''}`}>
                            <p className="mission_text p1">Geekle started doing online events when offline events were stopped completely. We never stop and we are running the QA Global Summit Autumn'24. Our speakers are leading experts from all over the world who are ready to share what challenges QA experts face in their work.</p>
                            <p className="mission_text p1">Geekle has the unique experience to gather huge tech summits with 3'000+ attendees in different domains. Now we hope to make something the world has never seen before for the QA community.</p>
                        </div>
                        <Link
                            activeClass="active"
                            to='tickets'
                            spy={true}
                            smooth={true}
                            offset={20}
                            className='button mission_btn'
                        >
                            GET A TICKET
                        </Link>
                    </div>
                </div>
            </section>
        )
    }
}