import React from 'react';
import './Reviews.css';
import Silva from '../../img/reviews/Silva.png';
import Enda from '../../img/reviews/Enda.png';
import Susheel from '../../img/reviews/Susheel.png';
import Dishath from '../../img/reviews/Dishath.png';
import Huzvarova from '../../img/reviews/Huzvarova.png';
import Nora from '../../img/reviews/Nora.png';
import stars from '../../img/main/stars.png';
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";

export default class Reviews extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      reviews: [
        {
          photo: Enda,
          name: 'Enda Brody',
          title: `Really got what lot more than i expect from the conference not just new technologies but new ways of thinking in terms of quality in software. Speakers are very approachable and give me as young enough qa engineer something to aspire towards.`,
        },
        {
          photo: Silva,
          name: 'Afonso Silva',
          title: `Thank you for the a great event, for all the staff support and despite the circumstances for being able to run it so smoothly.`,
        },
        {
          photo: Nora,
          name: 'Gustavo Dalla Nora',
          title: `It was a very rich learning experience to be able to see what people with Software Architecture roles in companies around the world are doing to talke Software Architecture challenges in modern industry.`,
        },
        {
          photo: Susheel,
          name: 'Susheel Vigrahala',
          title: `The summit was surely lucrative. It brings us to learn new ideas and also get to meet various QA engineers all over the world and get to share their projects and ideas on career improving etc. I find it useful and a ray of hope to continue to take further challenges in this role.`,
        },
        {
          photo: Huzvarova,
          name: 'Katarina Huzvarova',
          title: `I'm really happy to be part of QA world and also very happy that there are so many testers all over the world who joined this event and shared their knowledge. Thank you!!!`,
        },
        {
          photo: Dishath,
          name: 'Dishant Gandhi',
          title: `QA itself critical in any industry, with such responsible role collaboration and sharing play an important part and Geekle did incredible job by providing such platform/place for QA. Thank you so much and God bless you!`,
        },
      ]
    }
  }

  renderReviews(item, i) {
    return (
      <div className='reviews_item' key={`reviews_item-${i}`}>
        <img className="reviews_stars" src={stars} alt="" />
        <p className='reviews_title p1'>"{item.title}"</p>
        <img className='reviews_photo' src={item.photo} alt="" />
        <p className="reviews_item_name p1">{item.name}</p>
      </div >
    )
  }

  render() {
    return (
      <section className="reviews" id='reviews'>
        <div className='container reviews_wrap'>
          <h2 className='reviews_main_title colorful'>What people say</h2>
          <div className="review_block">
            <Carousel className="review_slider" showThumbs={false} showStatus={false} infiniteLoop swipeable={false} emulateTouch={false}>
              {this.state.reviews.map((item, i) => this.renderReviews(item, i))}
            </Carousel>
          </div>
        </div>
      </section>
    )
  }
}