import React from "react"; 
import './App.css';
import TawkMessengerReact from '@tawk.to/tawk-messenger-react';
import Header from './components/Header/Header.jsx';
import Main from './components/main/main.jsx';
import Banner from './components/Banner/Banner.jsx';
import Numbers from './components/Numbers/Numbers.jsx';
import SpeakersFrom from './components/Speakers from/Speakers from.jsx';
import Photos from './components/Photos/Photos.jsx';
// import Workshops from './components/Workshops/Workshops.jsx';
// import Speakers from './components/Speakers/Speakers.jsx';
// import Packages from './components/Packages/Packages.jsx';
import PackagesWrap from './components/PackagesWrap/PackagesWrap.jsx'
import AskCompany from "./components/AskCompany/AskCompany";
import Enterprise from "./components/Enterprise/Enterprise";
import BecomeSpeaker from "./components/BecomeSpeaker/BecomeSpeaker";
// import Sponsors from "./components/Sponsors/Sponsors";
import Partners from "./components/Partners/Partners";
import Speakers from "./components/Speakers/Speakers";
import Mission from "./components/Mission/Mission";
import Reviews from './components/Reviews/Reviews.jsx';
import Team from './components/Team/Team.jsx';
import Footer from './components/footer/Footer.jsx';
import CountDownBanner from './components/CountDownBanner/CountDownBanner.jsx'
import EventAbout from './components/Event_about/Event_about';
import Audience from './components/Audience/Audience';
import RunningString from './components/Running_string/Running_string';
// import Quote from './components/Quote/Quote';
import TagCloud from './components/TagCloud/TagCloud';
import PC from './components/PC2/PC';
// import PC from './components/PC/PC';
import AIPanel from './components/AIPanel/AIPanel';
import Agenda from './components/Agenda/Agenda';
import Sponsorship from './components/Sponsorship/Sponsorship';
import GoToPackages from './components/GoToPackages/GoToPackages';
import LatestProduct from './components/LatestProduct/LatestProduct';

const App = (props) => {
  return (
    <main>
      <Header />
      {/* <GiftCard /> */}
      <Main />
      {/* <Banner /> */}
      {/* <Quote /> */}
      <Numbers />
      <SpeakersFrom />
      <LatestProduct />
      <Audience />
      {/* <EventAbout /> */}
      <GoToPackages />
      <RunningString />
      <Speakers />
      {/* <Agenda /> */}
      {/* <AIPanel /> */}
      {/* <Workshops /> */}
      <PackagesWrap />
      <TagCloud />
      {/* <AskCompany /> */}
      {/* <Enterprise /> */}
      <BecomeSpeaker />
      {/* <PC /> */}
      {/* <Partners /> */}
      <Sponsorship />
      {/* <Sponsors /> */}
      <Reviews />
      <Photos />
      {/* <Team /> */}
      <Mission />
      <TawkMessengerReact
        propertyId="62d53950b0d10b6f3e7ce13c"
        widgetId="1g92ed1m4" />
      <Footer />
      <CountDownBanner/>
    </main>
  );
}

export default App;