import React from 'react';
import { Link } from "react-scroll";
import './LatestProduct.css';
import ArrowsL from "./img/arrows-l.svg";
import ArrowsR from "./img/arrows-r.svg";

const LatestProduct = () => {
    return (
        <div className="latest_product-wrap">
            <div className="container">
                <div className="product_video mob">
                    <iframe src="https://player.vimeo.com/video/1011614722?h=6aed743e7a&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479" width="100%" height="100%" frameborder="0" allow="autoplay; fullscreen; picture-in-picture; clipboard-write" title="Check out Latest Geekle Product"></iframe>
                </div>
                <h3 class="latest_product-title"> &lt; Check out Latest <span class="geekle_green">Geekle</span> Product &gt;</h3>
                <ul className="latest_product">
                    <li className="product-wrap">
                        <div class="product">Usual Tech Talks</div>
                    </li>
                    <li className="product-arrow desc">
                        <svg xmlns="http://www.w3.org/2000/svg" width="40" height="8" viewBox="0 0 40 8" fill="none">
                            <path d="M39.3536 4.35355C39.5488 4.15829 39.5488 3.84171 39.3536 3.64645L36.1716 0.464466C35.9763 0.269204 35.6597 0.269204 35.4645 0.464466C35.2692 0.659728 35.2692 0.976311 35.4645 1.17157L38.2929 4L35.4645 6.82843C35.2692 7.02369 35.2692 7.34027 35.4645 7.53553C35.6597 7.7308 35.9763 7.7308 36.1716 7.53553L39.3536 4.35355ZM39 3.5L0 3.5L0 4.5L39 4.5V3.5Z" fill="#090B11"/>
                        </svg>
                    </li>
                    <li className="product-arrow mob">
                        <svg xmlns="http://www.w3.org/2000/svg" width="8" height="31" viewBox="0 0 8 31" fill="none">
                            <path d="M3.64645 30.3536C3.84171 30.5488 4.15829 30.5488 4.35355 30.3536L7.53553 27.1716C7.73079 26.9763 7.73079 26.6597 7.53553 26.4645C7.34027 26.2692 7.02369 26.2692 6.82843 26.4645L4 29.2929L1.17157 26.4645C0.97631 26.2692 0.659727 26.2692 0.464465 26.4645C0.269203 26.6597 0.269203 26.9763 0.464465 27.1716L3.64645 30.3536ZM4.5 30L4.5 2.18557e-08L3.5 -2.18557e-08L3.5 30L4.5 30Z" fill="black"/>
                        </svg>
                    </li>
                    <li className="product-wrap">
                        <div class="product-desc">New format</div>
                        <div class="product">Micro Workshops</div>
                    </li>
                    <li className="product-arrow mob">
                        <svg xmlns="http://www.w3.org/2000/svg" width="8" height="31" viewBox="0 0 8 31" fill="none">
                            <path d="M3.64645 30.3536C3.84171 30.5488 4.15829 30.5488 4.35355 30.3536L7.53553 27.1716C7.73079 26.9763 7.73079 26.6597 7.53553 26.4645C7.34027 26.2692 7.02369 26.2692 6.82843 26.4645L4 29.2929L1.17157 26.4645C0.97631 26.2692 0.659727 26.2692 0.464465 26.4645C0.269203 26.6597 0.269203 26.9763 0.464465 27.1716L3.64645 30.3536ZM4.5 30L4.5 2.18557e-08L3.5 -2.18557e-08L3.5 30L4.5 30Z" fill="black"/>
                        </svg>
                    </li>
                    <li className="product-wrap">
                        <img class="product-arrows" src={ArrowsL} alt="" />
                        <div>
                            <div class="product product_small">Problem Statement</div>
                            <div class="product product_small">Innovation</div>
                            <div class="product product_small">Resources: test code, templates, GitHub repos, schemas</div>
                            <div class="product product_small">Practicum: learning by following</div>
                        </div>
                        <img class="product-arrows" src={ArrowsR} alt="" />
                    </li>
                    <li className="product-arrow mob">
                        <svg xmlns="http://www.w3.org/2000/svg" width="8" height="31" viewBox="0 0 8 31" fill="none">
                            <path d="M3.64645 30.3536C3.84171 30.5488 4.15829 30.5488 4.35355 30.3536L7.53553 27.1716C7.73079 26.9763 7.73079 26.6597 7.53553 26.4645C7.34027 26.2692 7.02369 26.2692 6.82843 26.4645L4 29.2929L1.17157 26.4645C0.97631 26.2692 0.659727 26.2692 0.464465 26.4645C0.269203 26.6597 0.269203 26.9763 0.464465 27.1716L3.64645 30.3536ZM4.5 30L4.5 2.18557e-08L3.5 -2.18557e-08L3.5 30L4.5 30Z" fill="black"/>
                        </svg>
                    </li>
                    <li className="product-wrap">
                        <div class="product">New practical skill</div>
                    </li>
                    <li className="product-wrap">
                        <div className="product_video desc">
                            <iframe src="https://player.vimeo.com/video/1011614722?h=6aed743e7a&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479" width="100%" height="100%" frameborder="0" allow="autoplay; fullscreen; picture-in-picture; clipboard-write" title="Check out Latest Geekle Product"></iframe>
                        </div>
                        <Link
                            activeClass="active"
                            to='tickets'
                            spy={true}
                            smooth={true}
                            offset={-80}
                            className='main_btn button product_btn'
                        >
                            <span className='main_btn_span'>Get Ticket</span>
                        </Link>
                    </li>
                </ul>
            </div>
        </div>
    );
};

export default LatestProduct;