import './main.css';
import { Link } from "react-scroll";
import React from 'react';
import title from '../../img/main/title.svg'
import creative from '../../img/main/creative.png'
import creativeMob from '../../img/main/creative-mob.png'
import CopyBtn from './copyBtn/copyBtn'

export default class Main extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            firstScreenHeight: document.documentElement.clientHeight,
        }

        this.changeScreenHeight = this.changeScreenHeight.bind(this);
    }

    changeScreenHeight() {
        this.setState({
            firstScreenHeight: document.documentElement.clientHeight
        });

        document.querySelector('.main_wrap').style.height = this.state.firstScreenHeight + 'px';
    }

    componentWillMount = () => {
        window.addEventListener("resize", this.changeScreenHeight);
        return () => window.removeEventListener("resize", this.changeScreenHeight);
    }

    componentDidMount() {
        this.changeScreenHeight();
    }

    render() {
        return (
					<div className='main_wrap'>
						<div className='main_bg'></div>
						<div className='container main_block'>
							<div className='main_content'>
								<h1 className='main_title'>
									<span className='main_title--blue'>QA</span> Global
									<span className='main_title--plashka main_title--small'>
										{' '}
										Summit Autumn'24
									</span>
								</h1>
								{/* <div className='main_title_img_block'>
                            <img className='main_title_img main_title_img--pc' src={title} alt="" />
                            <img className='main_title_img main_title_img--mob' src={title} alt="" />
                        </div> */}
								<div className='main_img_block--mob'>
									<img className='main_img' src={creativeMob} alt='' />
								</div>
								<p className='newFormat'>New Format Micro Workshops</p>
								<p className='main_descr p1'>Online Summit for QA Engineers</p>
								<p className='main_descr p1'>November 26-27, Online</p>
								<div className='main_btns_wrap'>
									<Link
										activeClass='active'
										to='tickets'
										spy={true}
										smooth={true}
										offset={80}
										className='main_btn button'
									>
										GET TICKETS
									</Link>
									<CopyBtn/>
									{/* <Link
										activeClass='active'
										to='tickets'
										spy={true}
										smooth={true}
										offset={80}
										className=' button main_btn_share'
									>
										COPY LINK
									</Link> */}
									{/* <a rel='norefferer' target='_blank' href="https://geekle.us/stage_/qa23" className='button main_btn_share'>Watch now</a> */}
									{/* <CopyBtn /> */}
								</div>
							</div>
							<div className='main_img_block main_img_block--pc'>
								<img className='main_img' src={creative} alt='' />
							</div>
						</div>
					</div>
				);
    }
}