import React from 'react';
import './AIPanel.css';
import Amaro from '../../img/AIPanel/Petermann.png'
import Verschelling from '../../img/AIPanel/Minsky.png'
import Menesklou from '../../img/AIPanel/Adelman.png'
import Pankratov from '../../img/AIPanel/Gill.png'

export default class AIPanel extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      speakers: [
        {
          photo: Amaro,
          role: 'QA Manager',
          name: 'Karen Amaro',
          company: 'CES',
        },
        {
          photo: Verschelling,
          role: 'Sr. Test Consultant',
          name: 'Andre Verschelling',
          company: 'Alten',
        },
        {
          photo: Menesklou,
          role: 'CEO',
          name: 'Jonas Menesklou',
          company: 'AskUI',
        },
        {
          photo: Pankratov,
          role: 'Host',
          name: 'Pavlo Pankratov',
          company: 'Geekle',
        },
      ],
    }
  }

  renderPC(item, i) {
    return (
      <div className="panel-item" key={`team-${i}`}>
        <img className="panel-photo" src={item.photo} />
          <p className="panel-role">{item.role}</p>
          <p className="panel-name">{item.name}</p>
          <p className="panel-company">{item.company}</p>
      </div>
    )
  }

  render() {
    return (
      <div className="panel-wrap" id='panel'>
        <div className='container panel'>
          <h3 className='panel-title'>Panel Discussion</h3>
          <p className='panel-subtitle'>AI and LLM technology in QA</p>
          <div className='panel-items'>
            {this.state.speakers.map((item, i) => this.renderPC(item, i))}
          </div>
        </div>
      </div>
    )
  }
}